import { useCallback, useEffect, useRef, useState } from "react";
import { validateFile } from "..";

export default function useDropFile(callback?: (file: File) => Promise<void>) {
  const dragCount = useRef(0);
  const [fileIsHovering, setFileIsHovering] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const containerRef = useCallback((node: HTMLDivElement) => {
    setNode(node);
  }, []);

  const handleChanges = useCallback(
    async (file: File) => {
      if (!file || !(await validateFile(file))) return;
      setFile(file);
      callback && callback(file);
    },
    [callback],
  );

  const preventDefault = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      preventDefault(event);
      setFileIsHovering(false);
      dragCount.current = 0;

      const eventFiles = event.dataTransfer?.files;
      if (eventFiles && eventFiles.length > 0) {
        const file = eventFiles[0];
        handleChanges(file);
      }
    },
    [handleChanges, preventDefault, setFileIsHovering],
  );

  const handleDragEnter = useCallback(
    (event: DragEvent) => {
      preventDefault(event);
      dragCount.current++;
      if (event.dataTransfer?.items && event.dataTransfer.items.length !== 0) {
        setFileIsHovering(true);
      }
    },
    [preventDefault, setFileIsHovering],
  );

  const handleDragLeave = useCallback(
    (event: DragEvent) => {
      preventDefault(event);
      dragCount.current--;
      setFile(null);
      if (dragCount.current > 0 || !event.target) return;
      const tagName = (event.target as HTMLElement).tagName;
      if (tagName === "IFRAME") {
        setFileIsHovering(true);
        return;
      }
      setFileIsHovering(false);
    },
    [preventDefault],
  );

  const handleDragOver = useCallback(
    (event: DragEvent) => {
      preventDefault(event);
    },
    [preventDefault],
  );

  useEffect(() => {
    if (!node) return;
    node.addEventListener("drop", handleDrop);
    node.addEventListener("dragenter", handleDragEnter);
    node.addEventListener("dragleave", handleDragLeave);
    node.addEventListener("dragover", handleDragOver);

    return () => {
      node.removeEventListener("drop", handleDrop);
      node.removeEventListener("dragenter", handleDragEnter);
      node.removeEventListener("dragleave", handleDragLeave);
      node.removeEventListener("dragover", handleDragOver);
    };
  }, [handleDrop, handleDragEnter, handleDragLeave, handleDragOver, preventDefault, node]);

  return { file, fileIsHovering, containerRef };
}
