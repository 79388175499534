import Image from "next/image";
import styled from "styled-components";
import Text from "./Text";

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100dvh;
  width: 100%;
  flex-direction: column;
  z-index: var(--z-layer-loader);
`;

const LoadingImage = styled(Image)`
  animation: spin 1.4s var(--timing-easeInOutQuart) infinite;
  width: 4rem;
  height: 4rem;
`;

function Loading({ height = 20, text }: { height?: number; text?: string }) {
  return (
    <Container>
      <LoadingImage src="/img/worm_logo.png" alt="Bunches Logo" width={height} height={height} />
      {!!text && <Text>{text}</Text>}
    </Container>
  );
}

export default Loading;
