import Image from "next/image";
import styled from "styled-components";
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from "react";
import { UPLOAD_IMAGE } from "../../gql/utilities/uploadImage";
import { useMutation } from "@apollo/client";
import { validateFile } from "../../utilities";

// Valid imageFor Options
// "discover-asset"
// "message-image"
// "profile-cover-photo"
// "profile-picture"

const CloseImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
`;

const CloseImage = styled(Image)`
  display: block;
  width: 0.5rem;
  height: 0.5rem;
`;

const CloseImageActive = styled(Image)`
  display: none;
  width: 0.5rem;
  height: 0.5rem;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled(({ size, rounded, largeImage, pad, ...props }) => <div {...props} />)<{
  size: number;
  rounded: boolean;
  largeImage: boolean;
}>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: ${({ pad }) => (pad ? "0.3rem" : "0")};

  width: ${({ size }) => `${size.toString()}px`};
  height: ${({ size }) => `${size.toString()}px`};
  border-radius: ${({ rounded }) => (rounded ? "100%" : "")};
  overflow: ${({ rounded }) => (rounded ? "hidden" : "")};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover ${CloseImage} {
    display: none;
  }

  &:hover ${CloseImageActive} {
    display: block;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MessageImage = styled(({ isStart, ...props }) => <Image alt={props.alt ?? "Message Image"} {...props} />)<{ isStart: boolean }>`
  object-fit: ${({ isStart }) => (isStart ? "contain" : "cover")};
  border-radius: 0.5rem;
`;

function ImageUploader({
  displayComponent,
  inputId,
  mediaType,
  shouldUpload = false,
  imageFor,
  selectedImage,
  setSelectedImage,
  setImageUrl,
  imageUrl,
  className,
  pad = true,
  isStart = false,
  rounded = false,
  size = 40,
  largeImage = false,
}: {
  displayComponent: ReactNode;
  inputId: string;
  mediaType: string;
  shouldUpload?: boolean;
  imageFor: string;
  selectedImage: File | null;
  setSelectedImage: Dispatch<SetStateAction<File | null>>;
  setImageUrl: Dispatch<SetStateAction<string>>;
  imageUrl?: string;
  className?: string;
  pad?: boolean;
  isStart?: boolean;
  rounded?: boolean;
  size?: number;
  largeImage?: boolean;
}) {
  const [performUploadImage, { data, reset }] = useMutation(UPLOAD_IMAGE);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const resetInput = () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.value = "";
  };

  useEffect(() => {
    if (!data || !data.uploadImage) return;

    setImageUrl(data.uploadImage);
    reset();
  }, [data, setImageUrl, reset]);

  useEffect(() => {
    if (!selectedImage || !imageFor || shouldUpload === false) return;
    performUploadImage({ variables: { file: selectedImage, for: imageFor } });
  }, [imageFor, selectedImage, performUploadImage, shouldUpload]);

  const handleFileInput = async (e) => {
    if (selectedImage) return;
    const image = e.target.files[0] ?? false;
    if (!(await validateFile(image))) return resetInput();
    setSelectedImage(image);
    if (!image) return;
    const input = document?.getElementById(`${inputId}-display`);
    if (input) input.src = image;
    resetInput();
  };

  return (
    <Container className={className} size={size} rounded={rounded} largeImage={largeImage} pad={pad}>
      {(!(selectedImage || imageUrl) || mediaType !== "image") && displayComponent}
      {(selectedImage || imageUrl) && mediaType === "image" && (
        <MessageImage
          alt="Message Media"
          isStart={isStart}
          src={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
          width={size}
          height={size}
        />
      )}
      {!isStart && selectedImage && mediaType === "image" && (
        <CloseImageContainer
          onClick={() => {
            setImageUrl("");
            setSelectedImage(null);
            resetInput();
            return;
          }}
        >
          <CloseImage src="/icons/close.png" alt="Close" width={16} height={16} />
          <CloseImageActive src="/icons/close.png" alt="Close" width={16} height={16} />
        </CloseImageContainer>
      )}
      <HiddenInput
        id={inputId}
        ref={inputRef}
        className="hidden"
        type="file"
        accept="image/*"
        onChange={handleFileInput}
        onClick={handleFileInput}
      />
    </Container>
  );
}

export default ImageUploader;
