import { ReactNode } from "react";
import styled from "styled-components";

const Text = styled.p`
  color: var(--color-gray-3);
  font-size: 0.75rem;
  line-height: 1.4;
  margin: 0;
  display: inline;
`;

export default function Subtext({ children, className }: { children: ReactNode; className?: string }) {
  return <Text className={className}>{children}</Text>;
}
